<template>
  <Box> <!-- container -->

    
    <TitleRow v-if="isEdit">
      Update Vehicle or Trailer
    </TitleRow>
    <TitleRow v-else>
      Add Vehicle or Trailer
    </TitleRow>
      
    <Row :showBorder="true">
      <Column>
        <Box>

          <TitleRow :subtitle="true">
            Details
          </TitleRow>
        
          <ValueRow :showBorder="true" v-if="tip"  >
          {{ tip }} 
          </ValueRow>
            
          <ValueRow align="left">
          </ValueRow>
          
          <Row :showBorder="false">
            <Column :width="5" class="w3-center">
              <VehicleImage :defaultValue="defaultValue" :imageData="imageData" />
              <ErrorRow :error="vehicleImageError" />
              <input type="file" v-on:change="handleFileUpload" />
              <div v-if="status">{{ status }}</div>
            </Column>
            <Column :width="10">
              <Box>
                <Row>
                  <Column>
                    <Box>
                      <KeyValue 
                        :title="VehicleFields.NAME" 
                        :required="isValueRequired(VehicleFields.NAME)"
                        :mandatory="true"
                        :placeholder="domain.lang().get(catalog.VEHICLE.NAME_FIELD_TITLE)" 
                        :defaultValue="decode(kvps['name'])" 
                        :disabled="disableAll" 
                        v-on:value="setValue" />
                    </Box>
                  </Column>
                </Row>
                <Row>
                  <Column :width="8">
                    <Box>
                      <KeyValue 
                        title="odometer" 
                        :placeholder="odometerTitle" 
                        :defaultValue="decode(kvps['odometer'])" 
                        :disabled="disableAll"
                        :hint="odometerHint"
                        v-on:value="setOdometerValue" />
                    </Box>
                  </Column>
                  <Column :width="7">
                    <Box>
                      <KeyList 
                        :title="VehicleFields.ODOMETER_TYPE" 
                        placeholder="Units" 
                        :required="isValueRequired(VehicleFields.ODOMETER_TYPE)"
                        :mandatory="true"
                        :defaultValue="vehicle().odometerType()" 
                        :disabled="disableAll" 
                        :options="unitTypes" 
                        v-on:value="setValue" />
                    </Box>
                  </Column>
                </Row>
                <TitleRow :subtitle="true">
                    Vehicle and Brake Type
                </TitleRow>
                <KeyList 
                  :title="VehicleFields._CATEGORY" 
                  :placeholder="catalog.VEHICLE.CATEGORY_FIELD_TITLE" 
                  :defaultValue="rid(kvps[VehicleFields.CATEGORY])" 
                  :disabled="disableAll" 
                  :options="categories"
                  :required="isValueRequired(VehicleFields._CATEGORY)"
                  :mandatory="true"
                  v-on:value="setVehicleTypeValue" />
                <KeyList 
                  :title="VehicleFields.BRAKE_TYPE" 
                  :placeholder="catalog.VEHICLE.BRAKETYPE_FIELD_TITLE" 
                  :defaultValue="vehicle().brakeType()" 
                  :disabled="disableAll" 
                  :options="brakingTypes" 
                  :required="isValueRequired(VehicleFields.BRAKE_TYPE)"
                  :mandatory="true"
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
          
          <SpacerRow />
              
          <TitleRow :subtitle="true">
            Attributes
          </TitleRow>
            
          <Row :showBorder="false">
            <Column>
              <Box>
                <KeyValue title="summary" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.ATTRIBUTES_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['summary'])" 
                  :disabled="disableAll" 
                  v-on:value="setValue" />
              </Box>
            </Column>
            <Column>
              <Box>
                <KeyValue 
                  title="colour" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.COLOUR_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['colour'])" 
                  :disabled="disableAll" 
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
    
          <Row :showBorder="false">
            <Column>
              <Box>
                <KeyValue 
                  title="make" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.MAKER_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['make'])" 
                  :disabled="disableAll" 
                  v-on:value="setValue" />
              </Box>
            </Column>
            <Column>
              <Box>
                <KeyValue title="model" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.MODEL_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['model'])"
                  :disabled="disableAll" 
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
        
          <Row :showBorder="false">
            <Column>
              <Box>
                <KeyValue title="vin" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.VIN_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['vin'])" 
                  :disabled="disableAll" 
                  v-on:value="setValueVin" />
                </Box>
            </Column>
            <Column>
              <Box v-if="showVehicleWeight">
                <KeyValue title="weight" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.WEIGHT_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['weight'])" 
                  :disabled="disableAll" 
                  hint="Helps drivers know what vehicle and trailer combo they are allowed to drive"
                  type="number" 
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
    
          <Row :showBorder="false">
            <Column>
              <Box>
                <KeyYear 
                  :title="VehicleFields.YEAR" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.YEAR_FIELD_TITLE)" 
                  :defaultValue="decode(kvps['year'])" 
                  :disabled="disableAll"
                  :required="isValueRequired(VehicleFields.YEAR)"
                  :mandatory="true"
                  v-on:value="setValue" />
              </Box>
            </Column>
            <Column>
              <Box>
                <KeyValue 
                  :title="VehicleFields.PLATE" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.PLATE_FIELD_TITLE)" 
                  :defaultValue="decode(kvps[VehicleFields.PLATE])" 
                  :disabled="disableAll" 
                  :required="isValueRequired(VehicleFields.PLATE)"
                  :mandatory="true"
                  v-on:value="setValueVin" />
              </Box>
            </Column>
          </Row>
    
          <SpacerRow />
          <TitleRow v-if="allowsChecklists()" :subtitle="true">Checklists (NEW!)</TitleRow>
          <ValueRow v-if="allowsChecklists()">{{ MC.CheckList.Definition.value() }}</ValueRow>
          <Row>
           <Column :width="15">
            <Box>
             <KeyList v-if="allowsChecklists()"
                  :title="VehicleFields.Checklists" 
                  :placeholder="MC.Vehicle.ChecklistLabel.value()" 
                  :defaultValue="vehicle().checklists().first().id()" 
                  :disabled="disableAll" 
                  :options="checklists"
                  v-on:value="setCheckListValue" />
            </Box>
           </Column>
          </Row>
          
          <SpacerRow />
          
          <TitleRow :subtitle="true">Safety and Schedule</TitleRow>
          <Row :showBorder="false">
            <Column :width="5">
              <Box>
                <JurisdictionEdit 
                  :disabled="disableAll"
                  :vehicleData="data()"
                  :required="isValueRequired(VehicleFields.JURISDICTION)"
                  :mandatory="true"
                  v-on:setValue="setValue"/>
              </Box>
            </Column>
            <Column :width="10">
              <Box>
                <KeyList 
                  :title="VehicleFields.SCHEDULE" 
                  :placeholder="domain.lang().get(catalog.VEHICLE.SCHEDULE_FIELD_TITLE)" 
                  :defaultValue="rid(kvps[VehicleFields.SCHEDULE])" 
                  :disabled="disableAll" 
                  :options="schedules"
                  :required="isValueRequired(VehicleFields.SCHEDULE)"
                  :mandatory="true"
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
              
          <Row :showBorder="false">
            <Column :width="5">
              <Box>
                <KeyDate title="cvorDate" 
                  placeholder="Last Safety Date"
                  hint="Last semi or annual safety"
                  :defaultValue="decode(kvps['cvorDate'])" 
                  :disabled="disableAll" 
                  v-on:value="setValue" />
              </Box>
            </Column>
            <Column :width="10">
              <Box>
                <KeyValue title="cvorNumber" 
                  placeholder="Last Safety ID"
                  hint="Some semi or annual safetys have identification numbers" 
                  :defaultValue="decode(kvps['cvorNumber'])" 
                  :disabled="disableAll" 
                  v-on:enter="done" 
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
          
          <Row :showBorder="false">
              <Column :width="7">
                <Box>
                  <LabelValueRow>
                      <template #label>Last Modified By</template>
                      <template #value> {{ modifiedBy() }} </template>
                  </LabelValueRow>
                </Box>
              </Column>
              <Column :width="8">
                <Box>
                  <LabelValueRow>
                      <template #label>Last Modified Date</template>
                      <template #value> {{ modifiedDate() }} </template>
                  </LabelValueRow>
                </Box>
              </Column>
          </Row>
            
        </Box>
      </Column>
    </Row>
    
  </Box> <!-- container -->
</template>

<script>
import heic2any from "heic2any";

import { mapGetters } from 'vuex';
import KeyValue from '@/components/input/KeyValue.vue';
import KeyDate from '@/components/input/KeyDate.vue';
import KeyYear from '@/components/input/KeyYear.vue';
import KeyList from '@/components/input/KeyList.vue';
import ContentUtils from '@/utils/ContentUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import VehicleImage from '@/views/portal/vehicles/VehicleImage.vue';
import VehicleUtils from '@/views/portal/vehicles/Utils.js';

import ErrorRow from '@/components/row/ErrorRow.vue';
import MC from "@/domain/session/MC.js";

import ImageImporter from '@/utils/ImageUtils.js';
import JurisdictionEdit from '@/views/portal/vehicles/edit/JurisdictionEdit.vue';
import Catalog from '@/domain/session/CanadianEnglish.js';

import Vehicle from '@/domain/model/vehicle/Vehicle.js';
//import CheckList from '@/domain/model/checklist/CheckList.js';
import ClientException from '@/domain/model/client/ClientException.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import ValueRow from '@/portals/shared/library/value/Value.vue';

export default {
  name: 'vehicle-edit',
  components: {
    // 1. setup page components
    KeyValue,
    KeyList,
    KeyDate,
    KeyYear,
    ErrorRow,
    TitleRow,
    SpacerRow,
    VehicleImage,
    JurisdictionEdit,
    Box, Row, Column,
    LabelValueRow,
    ValueRow,
  },
  props: {
    // 2. setup external parameters
    defaultValue: Object,
    disableAll: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    checkRequirements: { type: Boolean, default: false },
    tip: { type: String, default: null },
  },
  data() {
    // 3. setup page data structures
    return {
      kvps: this.defaultValue,
      imageData: null,
      status: null,
      images: {},
      schedules: [],
      checklists: [],
      brakingTypes: [],
      unitTypes: [],
      categories: [],
      file: '',
      meterTitle: null,
      vehicleImageError: null,
      catalog: Catalog.KEYS,
      VehicleFields: Vehicle.FIELDS,
      odometerHint: null,
      odometerRequired: false,
      missing: null,
      showVehicleWeight: true,
      MC: new MC(),
    };
  },
  computed: {
    // 4. setup dynamic values
    ...mapGetters([
                   'auth_user', 
                   'auth_client', 
                   'auth_connected', 
                   'vehicles_found', 
                   'companys_found', 
                   'categorys_found', 
                   'schedules_found',
                   'users_store',
                   'domain',
                   ]),
    odometerTitle: function() {
      return this.meterTitle;
    },
    navigator: () => navigator,
    isNameRequired: function() {
      return this.missing === this.VehicleFields.NAME;
    },
  },
  watch: {
    checkRequirements() {
      if (this.checkRequirements) {
        if (this.requirementsMet()) {
          this.$emit("save", true);
        } else {
          this.$emit("save", false)
        }
      }
    }
  },
  mounted: function () {
    // 5. setup before displayed
    if (this.schedules_found) {
      var scheduleList = this.schedules_found.list;
      for (var i = 0; i < scheduleList.length; i++) {
        var schedule = scheduleList[i];
        if (StringUtils.isEmpty(schedule.name)) {
          continue;
        }
        this.schedules.push(schedule);
      }
      this.schedules = this.schedules.sort(VehicleUtils.SortByName);
    }
    this.categories = ContentUtils.CATEGORIES();
      
    this.brakingTypes = [
      { '@rid': 'abs', name: 'Air' },
      { '@rid': 'ebs', name: 'Electric' },
      { '@rid': 'hbs', name: 'Hydraulic' },
      { '@rid': 'nbs', name: 'None' },
    ];
    
    this.unitTypes = [
                         { '@rid': 'KM', name: 'Kilometers' },
                         { '@rid': 'MI', name: 'Miles' },
                       ];
    
    this.meterTitle = this.domain.lang().get(this.catalog.VEHICLE.ODOMETER_FIELD_TITLE);
    if (this.vehicle().isTrailer()) {
      this.meterTitle = this.domain.lang().get(this.catalog.VEHICLE.HUBMETER_FIELD_TITLE);
    }
    if (this.domain) {
      this.checklists = this.domain.checklists().list().sort(VehicleUtils.SortByName);
    }
  },

  methods: {
    allowsChecklists: function() {
      if (this.domain) {
        return this.domain.session().company().find().features().find().allowsCheckLists();
      }
      return false;
    },
    rid: function (value) {
      if (value) {
        return value[ConstUtils.FIELDS.ID];
      }
      return "";
    },
    vehicle: function() {
      if (this.defaultValue) {
        return new Vehicle(this.domain, this.defaultValue);
      }
      return new Vehicle(this.domain);
    },
    modifiedBy: function() {
      var ref = this.kvps[ConstUtils.FIELDS.MODIFIED_BY];
      if (ref) {
        var id = ref[ConstUtils.FIELDS.ID];
        var value = this.users_store.map[id];
        if (value) {
          return this.decode(value.first);
        }
      }
      return "ReadyChek System User";
    },
    modifiedDate: function() {
      var value = this.kvps[ConstUtils.FIELDS.MODIFIED_DATE];
      return ContentUtils.decodeDateTime(value);
    },
    setVehicleTypeValue: function(kvp) {
      var value = kvp.value;
      if (value === "#23:5") {
        this.showVehicleWeight = false;
      } else {
        this.showVehicleWeight = true;
      }
      this.setValue(kvp);
    },
    setValueVin: function(kvp) {
      if (kvp.value) {
        kvp = { key: kvp.key, value: kvp.value.toUpperCase() };
      }
      this.setValue(kvp);
    },
    setValue: function (kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      this.setOdometerTitle();
      this.$emit('value', kvp);
    },
    setCheckListValue: function(kvp) {
      if (StringUtils.isEmpty(kvp.value)) {
        return;
      }
      
      if (!this.domain) {
        return;
      }
      
      var cl = this.domain.checklists().findById(kvp.value);
      if (cl.isNew()) {
        return;
      }
      
      kvp.value = cl.pointer().map().data;
      
      kvp = { key: kvp.key, value: kvp.value};
      this.setValue(kvp)
    },
    setOdometerValue: function(kvp) {
      this.odometerHint = null;
      if (isNaN(kvp.value)) {
        this.odometerHint = "This must be a number";
      } else {
        this.setValue(kvp);
      }
    },
    setOdometerTitle: function() {
      var title = this.catalog.VEHICLE.ODOMETER_FIELD_TITLE;
      var categoryId = this.kvps[Vehicle.FIELDS.CATEGORY];
      if (categoryId) {
        var category = this.domain.categorys().findById(categoryId);
        if (category.isTrailer()) {
          title = this.catalog.VEHICLE.HUBMETER_FIELD_TITLE
        }
      }
      this.meterTitle = this.domain.lang().get(title);
    },
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    data: function() {
      return this.defaultValue;
    },
    handleFileUpload: function (e) {
      this.vehicleImageError = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      var file = files[0];

      if (!StringUtils.contains(file.type, 'image')) {
        this.vehicleImageError = { 
            msg: 'The image must be an image and be 200 x 150 pixels in size.', 
            id: 'image invalid format' };
        return;
      }

      var vm = this;
      var fileName = file.name.toLowerCase();
      if (StringUtils.contains(fileName, ".heic")) {
        this.status = "Converting file please wait...";
        var params = {
            blob: file,
            toType: "image/png",
          };
        heic2any(params)
          .then(function (resultBlob) {
            var properties = { type: "image/png", lastModified: new Date().getTime() };
            let newFile = new File([resultBlob], fileName + ".png", properties) ;
            vm.readImage(newFile)
          })
          .catch(function (x) {
            vm.domain.logger().info(x);
            var ce = new ClientException(vm.domain);
            ce
              .withStackTrace(x)
              .withMaker(vm.navigator.appVersion)
              .withDevice(vm.domain.client().deviceId)
              .withVersion(vm.domain.client().version)
              .with(vm.domain.session().employee())
              .with(vm.domain.session().company())
              .with(vm.domain.session().user())
              .withStateSaved()
              .withClient()
              .done();
            var exception = vm.domain.events().client().exception(ce);
            exception.send();
            vm.status = "There was a problem converting this image from HEIC to JPG";
          });
      } else {
        this.readImage(file);
      }
    },
    
    readImage: function(file) {
      this.status = "Resizing file please wait...";
      var vm = this;
      ImageImporter
      .resizeImage({ file: file, maxSize: 150 })
      .then((resizedImage) => {
        var reader = new FileReader();
        reader.onloadend = function() {
          var base64data = reader.result;
          vm.imageData = base64data;
          vm.$emit('value', {
            value: vm.imageData,
            key: ConstUtils.VEHICLE.FIELDS.IMAGEDATA,
          });
          vm.status = null;
        }
        reader.readAsDataURL(resizedImage);
        vm.domain.logger().info("done with image");
      })
      .catch((x) => {
        vm.domain.logger().info(x);
        var ce = new ClientException(vm.domain);
        ce
          .withStackTrace(x)
          .withMaker(vm.navigator.appVersion)
          .withDevice(vm.domain.client().deviceId)
          .withVersion(vm.domain.client().version)
          .with(vm.domain.session().employee())
          .with(vm.domain.session().company())
          .with(vm.domain.session().user())
          .withStateSaved()
          .withClient()
          .done();
        var exception = vm.domain.events().client().exception(ce);
        exception.send();
        vm.status = "There was a problem resizing this image";
      });
    },
    
    isValueRequired: function(value) {
      return this.missing === value;
    },
    
    requirementsMet: function() {
      this.missing = null;
      var met = ContentUtils.hasValue(this.kvps, this.VehicleFields.NAME);
      if (!met) {
        this.missing = this.VehicleFields.NAME;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.ODOMETER_TYPE);
      if (!met) {
        this.missing = this.VehicleFields.ODOMETER_TYPE;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields._CATEGORY);
      if (!met) {
        this.missing = this.VehicleFields._CATEGORY;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.BRAKE_TYPE);
      if (!met) {
        this.missing = this.VehicleFields.BRAKE_TYPE;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.YEAR);
      if (!met) {
        this.missing = this.VehicleFields.YEAR;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.PLATE);
      if (!met) {
        this.missing = this.VehicleFields.PLATE;
        return met;
      }

      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.JURISDICTION);
      if (!met) {
        this.missing = this.VehicleFields.JURISDICTION;
        return met;
      }
      
      met = ContentUtils.hasValue(this.kvps, this.VehicleFields.SCHEDULE);
      if (!met) {
        this.missing = this.VehicleFields.SCHEDULE;
        return met;
      }
      
      return met;
    },
    done: function () {
      this.$emit('done', this.kvps);
    },
  },
};
</script>
