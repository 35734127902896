<template>
  <KeyList 
    :title="vehicle.fields().JURISDICTION" 
    :placeholder="domain.lang().get(catelog.VEHICLE.PROVINCE)" 
    :options="options"
    :disabled="isSaving" 
    :required="required"
    :mandatory="mandatory"
    :defaultValue="vehicle.jurisdiction()" 
    v-on:value="setValue" />
</template>

<script>
import { mapGetters } from 'vuex'
import Vehicle from '@/domain/model/vehicle/Vehicle.js';
import KeyList from '@/components/input/KeyList.vue';
import ContentUtils from '@/utils/ContentUtils.js';
import Catelog from '@/domain/session/CanadianEnglish.js';

export default  {
  name: 'jurisdiction-edit',
  props: {
    isSaving: { type: Boolean, default: false },
    vehicleData: { type: Object, default: () => {} },
    mandatory:  {type: Boolean, default: false},
    required:   {type: Boolean, default: false},
  },
  components: {
    KeyList,
  },
  data() {
    return {
      options: ContentUtils.PROVINCES(),
      catelog: Catelog.KEYS,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    vehicle: function() {
      if (this.vehicleData) {
        return new Vehicle(this.domain, this.vehicleData);
      }
      return new Vehicle(this.domain);
    },
  },
  watch: {
    //
  },
  mounted: function () {
  },
  methods: {
    setValue: function (kvp) {
      this.$emit('setValue', {'key': kvp.key, 'value': kvp.value})
    },
  },
}
</script>